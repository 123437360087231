import React from "react"
import BackgroundImage from 'gatsby-background-image'
import { Fade } from "react-awesome-reveal";

const Banner = ({ image, bannerCaption, bannerCaptionBold }) => (
  <section className="banner">
    <BackgroundImage Tag="section" className="banner-image" fluid={image} backgroundColor={`#000`}>
      <div className="banner-overlay-vertical" />
      <div className="banner-content"></div>
      <Fade triggerOnce>
        <div className="banner-accreditation">
          <p><small>{bannerCaption}</small></p>
          <p>{bannerCaptionBold}</p>
        </div>
      </Fade>
    </BackgroundImage>
  </section>
)

export default Banner
