import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Banner from "../../components/banner"
import Content from "../../components/content"
import Gallery from "../../components/gallery"

const ScreenPrintingPage = ({ data, pageContext }) => {
  const {
    breadcrumb: { crumbs },
  } = pageContext;

  const title = data.markdownRemark.frontmatter.title;
  const caption = data.markdownRemark.frontmatter.caption;
  const bannerCaption = data.markdownRemark.frontmatter.banner.banner_caption;
  const bannerCaptionBold = data.markdownRemark.frontmatter.banner.banner_caption_bold;
  const bannerImage = data.markdownRemark.frontmatter.banner.banner_image.childImageSharp.fluid;
  const listItems = data.markdownRemark.frontmatter.list_items;
  const body = data.markdownRemark.html;
  const galleryImageOne = data.markdownRemark.frontmatter.gallery.gallery_image_one.childImageSharp.fluid;
  const galleryImageOneCaption = data.markdownRemark.frontmatter.gallery.gallery_image_one_caption;
  const galleryImageOneCaptionBold = data.markdownRemark.frontmatter.gallery.gallery_image_one_caption_bold;
  const galleryImageTwo = data.markdownRemark.frontmatter.gallery.gallery_image_two.childImageSharp.fluid;
  const galleryImageTwoCaption = data.markdownRemark.frontmatter.gallery.gallery_image_two_caption;
  const galleryImageTwoCaptionBold = data.markdownRemark.frontmatter.gallery.gallery_image_two_caption_bold;

  return (
    <Layout progressEnabled={true}>
      <SEO title={title} />
      <Banner image={bannerImage} bannerCaption={bannerCaption} bannerCaptionBold={bannerCaptionBold} />
      <Content title={title} crumbs={crumbs} caption={caption} listItems={listItems} body={body} />
      <Gallery galleryImageOne={galleryImageOne} galleryImageTwo={galleryImageTwo} galleryImageOneCaption={galleryImageOneCaption} galleryImageOneCaptionBold={galleryImageOneCaptionBold} galleryImageTwoCaption={galleryImageTwoCaption} galleryImageTwoCaptionBold={galleryImageTwoCaptionBold} />
    </Layout>
  )
}

export const query = graphql`
query {
  markdownRemark(frontmatter: { template_key: { eq: "screen-printing" } }) {
    frontmatter {
      title
      caption
      banner {
        banner_caption
        banner_caption_bold
        banner_image {
          childImageSharp {
            fluid(maxWidth: 900, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      list_items {
        title
        icon
      }
      gallery {
        gallery_image_one {
          childImageSharp {
            fluid(maxWidth: 900, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        gallery_image_one_caption
        gallery_image_one_caption_bold
        gallery_image_two {
          childImageSharp {
            fluid(maxWidth: 900, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        gallery_image_two_caption
        gallery_image_two_caption_bold
      }
    }
    html
  }
}
`

export default ScreenPrintingPage
