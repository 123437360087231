import React from "react"
import { Fade } from "react-awesome-reveal";
import Img from "gatsby-image"

const Gallery = ({ galleryImageOne, galleryImageTwo, galleryImageOneCaption, galleryImageOneCaptionBold, galleryImageTwoCaption, galleryImageTwoCaptionBold }) => (
  <div className="gallery">
      <div className="row no-gutters">
        <div className="col">
          <Fade triggerOnce>
            <div className="image-wrapper">
              <Img fluid={galleryImageOne} />
              <div className="caption-shadow" />
              <p>{galleryImageOneCaption}, <strong>{galleryImageOneCaptionBold}</strong></p>
            </div>
          </Fade>
        </div>
        <div className="col">
          <Fade triggerOnce>
            <div className="image-wrapper">
              <Img fluid={galleryImageTwo} />
              <div className="caption-shadow" />
              <p>{galleryImageTwoCaption}, <strong>{galleryImageTwoCaptionBold}</strong></p>
            </div>
          </Fade>
        </div>
      </div>
  </div>
)

export default Gallery
